<template>
  <v-row class="d-flex justify-center">
    <v-col v-for="icon in icons" :key="icon.name" class="d-flex max-width min-width justify-center">
      <v-img :src="`https://epd.directus.app/assets/${icon.url}?format=webp`" :alt="icon.name" />
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
const icons = [
  {
    name: 'paypal',
    url: '2d420adb-0066-4430-a34f-d2359e3acfd1.svg',
  },
  {
    name: 'mastercard',
    url: '74833a74-609d-477f-8bb6-6231f8a55793.svg',
  },
  {
    name: 'visa',
    url: '84fde805-8f39-4101-b692-1de580d4919c.svg',
  },
]
</script>
<style lang="scss" scoped>
.max-width {
  max-width: 90px !important;
}
.min-width {
  min-width: 60px !important;
}
</style>
